import { Helpers } from '@app/shared';
import { BaseModel, BaseModelInterface } from '@app/abstracts';
import {
	TextTranslated,
	TextTranslatedInterface,
} from '../text-translated/text-translated';
import { Language } from '../language';
export interface AboutInterface extends BaseModelInterface {
	created_at: number | Date;
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	description?: (string | TextTranslated | TextTranslatedInterface)[];
}
export interface AboutPayload {
	title?: (string | TextTranslated | TextTranslatedInterface)[];
	description?: (string | TextTranslated | TextTranslatedInterface)[];
}
type AboutPayloadKey = keyof AboutPayload;
export class About extends BaseModel<AboutInterface, AboutPayload> {
	/** Short function to get label of instance */
	getLabel(): string {
		return this.props._id;
	}
	getTitle() {
		try {
			let lng = localStorage.getItem('lang');
			if (this.props.title == null) {
				return '';
			}
			var tt = this.props.title.find(function (params: TextTranslated) {
				var l = params.props.lang as Language;
				return lng.toLowerCase() == l.props.name.toLowerCase();
			}) as TextTranslated;
			if (tt == null) {
				return '';
			}
			return tt.props.text;
		} catch (e) {
			return 'ohoh';
		}
	}
	getDescription() {
		try {
			let lng = localStorage.getItem('lang');
			if (this.props.description == null) {
				return '';
			}
			var tt = this.props.description.find(function (
				params: TextTranslated
			) {
				var l = params.props.lang as Language;
				return lng.toLowerCase() == l.props.name.toLowerCase();
			}) as TextTranslated;
			if (tt == null) {
				return '';
			}
			return tt.props.text;
		} catch (e) {
			return 'hoho';
		}
	}
	/** Denotes if the instance of title has been populated */
	titleExists(): boolean {
		return (
			!!this.props &&
			this.props.title instanceof Array &&
			(<TextTranslated[]>this.props.title).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Denotes if the instance of description has been populated */
	descriptionExists(): boolean {
		return (
			!!this.props &&
			this.props.description instanceof Array &&
			(<TextTranslated[]>this.props.description).every((item) => {
				return item instanceof TextTranslated && item.exists();
			})
		);
	}
	/** Populate the current instance from an object */
	fromObject(object: AboutInterface): void {
		this.props = Object.assign({}, object);
		this.props.created_at = Helpers.convertToDate(this.props.created_at);
		if (this.props.title instanceof Array) {
			this.props.title = (<TextTranslatedInterface[]>(
				this.props.title
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		if (this.props.description instanceof Array) {
			this.props.description = (<TextTranslatedInterface[]>(
				this.props.description
			)).map((item) => {
				return typeof item === 'object'
					? new TextTranslated(item)
					: item;
			});
		}
		this.next();
	}
	/** Convert the current instance to an object */
	toObject(): AboutInterface {
		const props = Object.assign({}, this.props);
		if (typeof props.title === 'undefined') {
			props.title = [];
		}
		if (typeof props.description === 'undefined') {
			props.description = [];
		}
		props.created_at = Helpers.convertToTimestamp(props.created_at);
		if (this.props.title instanceof Array) {
			props.title = (<TextTranslated[]>props.title).map((item) => {
				return item instanceof TextTranslated ? item.toObject() : item;
			});
		}
		if (this.props.description instanceof Array) {
			props.description = (<TextTranslated[]>props.description).map(
				(item) => {
					return item instanceof TextTranslated
						? item.toObject()
						: item;
				}
			);
		}
		return props;
	}
	/** Convert an instance to an object to be sent to the API */
	toPayload(): AboutPayload {
		const raw = this.toObject();
		const allowed = this.allowedKeys();
		const payload = Object.keys(raw)
			.filter((key) => allowed.includes(<any>key))
			.reduce((o, k) => {
				o[k] = raw[k];
				return o;
			}, {} as AboutInterface);
		payload.title = payload.title
			? payload.title.map((i) => this.extractId(i))
			: null;
		payload.description = payload.description
			? payload.description.map((i) => this.extractId(i))
			: null;
		return payload as AboutPayload;
	}
	/** List allowed keys to be sent to the API */
	protected allowedKeys(): AboutPayloadKey[] {
		return ['title', 'description'];
	}
}
